<template>
    <div class="map bigScreenBaseMap">
        <div :id="'mapidbox' + mapid" class="myMap"></div>
        <div id="mapType" v-show="mapInfo.mapTypeBtn">
            <div
                class="mapTypeCard vectorType"
                @click="baseLayerChange('vector')"
                :class="{ active: mapType == 'vector' }"
            >
                <span>地图</span>
            </div>
            <div
                class="mapTypeCard imgType"
                @click="baseLayerChange('img')"
                :class="{ active: mapType == 'img' }"
            >
                <span>影像</span>
            </div>
            <!-- <div
                class="mapTypeCard terType"
                @click="baseLayerChange('ter')"
                :class="{ active: mapType == 'ter' }"
            >
                <span>地形</span>
            </div> -->
        </div>
        <!-- 复位按钮 -->
        <img
            v-if="mapInfo.reSetBtnState"
            src="@image/bigScreen/icon/reset.png"
            class="resetIcon pointer"
            :style="{
                right: mapInfo.resetBtnRight ? mapInfo.resetBtnRight : '',
            }"
            @click="changeFitBounds(mapInfo.lat, mapInfo.lng, mapInfo.zoom)"
            title="复位"
        />
        <!-- 指北针 -->
        <img src="@image/bigScreen/icon/compass.png" class="compassPart" />
        <!-- 地图工具栏：量面, 测距, 图层控制, 定位 -->
        <div class="toolBox" v-if="mapInfo.showTool">
            <MapTool :basicMap="basicMap"></MapTool>
        </div>

        <!-- @changeVectortile="changeVectortile" -->
    </div>
</template>

<script>
import { $AXIOS } from "@request/http.js";
import MapTool from "./mapTool/mapTool.vue"; //项目数量
import { mapGetters, mapMutations, mapActions } from "vuex";
import { derectionData } from "@libs/dailyInspectAreaList"; //
export default {
    name: "map_tdt",
    props: {
        mapData: Object,
        // 地图id 默认随机数, 遥感监测需指定id
        mapid: {
            type: String,
            default: () => String(Math.random()),
        },
    },
    data() {
        return {
            derectionData,
            mapInfo: {
                reSetBtnState: true,
                mode: "fix", //fix 固定模式  fly 飞行模式  fit 缩放模式
                lng: window.mapCenter[1],
                lat: window.mapCenter[0],
                zoom: 12.6, //放大级别
                minZoom: 3, //缩放 最小
                maxZoom: 21, //缩放 最大
                zoomSnap: 0.1,
                zoomDelta: 0.25, //缩放层级
                fitBounds: window.mapFitBounds, //最适合大小展开
                baseMap: true, //是否加载底图
                baseMapNum: 0, //0地图 1影像 2地形
                mapTypeBtn: true, //是否展示地图切换按钮
                url: "", //矢量地图路径
                dragging: true, //拖拽
                showTool: false, //加载 点线面 工具
                dxtState: true, //加载地形图
                ycqHcqState: true, //遗产区 缓冲区
            },
            basicMap: "", //地图
            mapType: "", // 地图 影像 地形
            baseMap_1: null, //地图
            baseMap_2: null, //影像
            baseMap_3: null, //地形
            tag_1: null,
            tag_2: null,
            tag_3: null,
            baseGroup: null, //矢量瓦片图层组
            gqyxGroup: null, //高清影像
            gqyxTenGroup: null, //高清影像 10mm
            dxtGroup: null, //地形图
            noteGroup: null, //注记层
            ycqHcqGroup: null, //遗产区缓冲区
            areaList: [], //片区列表
            xcqLabelGroup: null, //巡查区Label
        };
    },
    mounted() {
        this.initMap();
        //marker 弹出框
        this.markerPopup = window.L.popup({
            closeOnClick: false,
            closeButton: false,
            offset: [0, 0],
            className: "markerPopup",
        });
    },
    components: {
        MapTool,
    },
    watch: {},
    methods: {
        ...mapActions(["getVectorStyle", "GetRcxcXcpq"]),
        initMap() {
            this.mapInfo = {
                ...this.mapInfo,
                ...this.mapData,
            };

            let id = `mapidbox${this.mapid}`;
            this.basicMap = window.L.map(id, {
                crs: window.L.CRS.EPSG3857,
                editable: true,
                zoomControl: false, //左上角放大 缩小
                zoom: this.mapInfo.zoom,
                minZoom: this.mapInfo.minZoom,
                maxZoom: this.mapInfo.maxZoom,
                zoomSnap: this.mapInfo.zoomSnap,
                zoomDelta: this.mapInfo.zoomDelta,
                dragging: this.mapInfo.dragging,
                attributionControl: false, //右下角 加载底图 产生的 链接 隐藏掉
            });
            window.myMap = this.basicMap;

            this.gqyxGroup = window.L.layerGroup().addTo(this.basicMap);
            this.gqyxTenGroup = window.L.layerGroup().addTo(this.basicMap);
            this.baseGroup = window.L.layerGroup().addTo(this.basicMap);
            this.noteGroup = window.L.layerGroup().addTo(this.basicMap);
            this.ycqHcqGroup = window.L.layerGroup().addTo(this.basicMap);
            this.areaGroup = window.L.layerGroup().addTo(this.basicMap);
            this.dxtGroup = window.L.layerGroup().addTo(this.basicMap);
            this.xcqLabelGroup = window.L.layerGroup().addTo(this.basicMap);
            this.basicMap.on("zoomend", (e) => {
                let level = e.target._zoom;
                if (level >= 18 && this.mapData.baseMapNum != null) {
                    if (!this.common.containGroup(this.gqyxTenGroup)) {
                        this.loadTenGqyx(this.gqyxTenGroup);
                    }
                } else {
                    this.clearLayersGroup(this.gqyxTenGroup);
                }
            });
            if (this.mapInfo.mapTypeBtn) {
                this.initControls();
            }
            // 加载地形图
            if (this.mapInfo.dxtState) {
                this.drawDxt(this.dxtGroup, window.MAP_DXT);
            }

            // 加载矢量地图
            if (this.mapInfo.ycqHcqState) {
                this.loadYcqHcqMap(this.ycqHcqGroup); //遗产区缓冲区
            }

            this.loadNoteMap(this.noteGroup); //注记

            // 地图模式
            switch (this.mapInfo.mode) {
                case "fix": // 固定模式
                    this.basicMap.setView(
                        [this.mapInfo.lat, this.mapInfo.lng],
                        this.mapInfo.zoom
                    );
                    break;
                case "fly": // 飞行模式
                    this.basicMap.flyTo(
                        [this.mapInfo.lat, this.mapInfo.lng],
                        this.mapInfo.zoom
                    );
                    break;
                case "fit": // 缩放模式
                    this.basicMap.fitBounds([this.mapInfo.fitBounds]);
                    break;
            }
            this.basicMap.on("click", (ev) => {
                console.log(ev.latlng.lng, ev.latlng.lat);
            });
            this.basicMap.on("zoom", (ev) => {
                console.log("放大等级:", ev.target._zoom);
            });

            // 加载底图
            if (this.mapInfo.baseMap) {
                this.loadBaseLayer(this.mapInfo.baseMapNum);
            }

            // 加载矢量地图
            if (this.mapInfo.url) {
                this.loadVectorMap();
            }

            this.$emit("update:myMap", this.basicMap);
            this.$emit("initmap", this.basicMap);
        },

        //右下角的 审图号
        initControls() {
            let mousePositionControl = window.L.control.mousePosition({
                position: "bottomright",
                separator: ",",
                emptystring: " ",
                lngFirst: true,
                numDigits: 5,
                prefix: "",
                showZoom: true,
            });
            mousePositionControl.addTo(this.basicMap); //显示鼠标位置的地理坐标

            //没有找到获取控件的方法，暂用此简单暴力不优雅的方式解决
            this.basicMap.on("zoom", (ev) => {
                // eslint-disable-next-line
                let mouseposition = $(".leaflet-control-mouseposition");
                // let mouseInfo = mouseposition.html();
                let lat = ev.target._lastCenter.lat.toFixed(2);
                let lng = ev.target._lastCenter.lng.toFixed(2);
                let zoomInfo =
                    "<span class='MapNumber' style='background:#ddd;color:#fff;'>审图号：GS(2018)1432号&nbsp</span>地图显示级别:" +
                    (this.basicMap.getZoom().toFixed(1) + 1) +
                    "级&nbsp&nbsp 经度:" +
                    lng +
                    "，纬度:" +
                    lat;
                mouseposition.html(zoomInfo);
            });
        },
        // 清空图层组
        clearLayersGroup(group) {
            if (this.common.containGroup(group)) {
                group.clearLayers();
            }
        },
        loadBaseLayer(num) {
            this.clearLayersGroup(this.gqyxGroup);
            this.clearLayersGroup(this.gqyxTenGroup);
            this.clearLayersGroup(this.baseGroup);
            this.clearLayersGroup(this.areaGroup);
            switch (num) {
                case 0:
                    this.drawBasicMap(this.baseGroup);
                    break;
                case 1:
                    this.loadGqyx(this.gqyxGroup);

                    break;
                case null:
                    break;
            }
        },
        // 底图背景
        async drawBasicMap(group) {
            let layerStyle = await this.getVectorStyle({
                url: window.MAP_ZTT,
            });
            // "遗产要素村寨",
            let nameList = ["古茶林", "分隔防护林", "村寨面", "土地利用"];
            let layers = [];
            layerStyle.layers.map((item) => {
                if (
                    item["source-layer"] &&
                    nameList.includes(item["source-layer"])
                ) {
                    layers.push(item);
                }
            });
            layerStyle.layers = layers;
            let allProvince1 = window.L.mapboxGL({
                accessToken: window.MAPBOX_TOKEN,
                style: layerStyle,
            }).addTo(group);
            this.common.showVectorLayers();
            this.mapboxMap = allProvince1.getMapboxMap();
            this.mapboxMap.on("load", () => {
                this.$nextTick(() => {
                    let myContainer = allProvince1._glMap.getContainer();
                    // myContainer.className=`leaflet-gl-layer mapboxgl-map ${item.zIndex}`;
                    myContainer.style.zIndex = 1;
                });
            });
        },
        //天地图影像地图注记
        loadNoteMap(group) {
            new window.L.TileLayer.WMTS(
                `http://t{s}.tianditu.gov.cn/cia_w/wmts?tk=${window.tk}`,
                {
                    zIndex: 10,
                    tileSize: 256,
                    layer: "cia",
                    style: "default",
                    tilematrixSet: "w",
                    crs: window.L.CRS.EPSG3857,
                    format: "tiles",
                    subdomains: "01234567",
                    minZoom: 3,
                    maxZoom: 21,
                    isBaseLayer: true,
                }
            ).addTo(group);
            this.common.showVectorLayers();
        },
        //遗产区缓冲区
        async loadYcqHcqMap(group) {
            let layerStyle = await this.getVectorStyle({
                url: window.MAP_ZTT,
            });

            let obj = JSON.parse(JSON.stringify(layerStyle));
            let ycshcq = [];
            obj.layers.map((item) => {
                if (item["source-layer"] == "遗产区划线") {
                    ycshcq.push(item);
                }
            });
            obj.layers = ycshcq;
            let allProvince1 = window.L.mapboxGL({
                accessToken: window.MAPBOX_TOKEN,
                style: obj,
            }).addTo(group);
            this.common.showVectorLayers();
            let mapboxMap = allProvince1.getMapboxMap();
            mapboxMap.on("load", () => {
                this.$nextTick(() => {
                    let myContainer = mapboxMap.getContainer();
                    myContainer.className = `leaflet-gl-layer mapboxgl-map ycqHcqMap`;
                });
            });
        },
        // 加载高清影像
        loadGqyx(group) {
            let layer = this.getWmtsLayer("img"); // 影像地图
            layer.addTo(group);
            new window.L.tileLayer(window.MAP_GQXY_50_2022, {
                zIndex: 5,
                minZoom: 0,
                maxZoom: 20,
                unlimited: true,
            }).addTo(group);
            // 加载片区
            if (this.areaList.length) {
                this.drawXcq(this.areaGroup, this.areaList);
            } else {
                this.getAreaInfo();
            }
        },
        // 加载高清影像
        loadTenGqyx(group) {
            new window.L.tileLayer(window.MAP_GQXY_10_2022, {
                minZoom: 18,
                zIndex: 5,
                maxZoom: 21,
            }).addTo(group);
        },
        // 画地形图
        async drawDxt(group, url) {
            let layerStyle = await this.getVectorStyle({
                url: url,
            });
            let list = [];
            layerStyle.layers.map((item) => {
                if (
                    item["source-layer"] &&
                    item["source-layer"] != "遗产区划线"
                ) {
                    list.push(item);
                }
            });
            layerStyle.layers = Array.from(list);
            let allProvince1 = window.L.mapboxGL({
                accessToken: window.MAPBOX_TOKEN,
                style: layerStyle,
            }).addTo(group);
            this.common.showVectorLayers();
            let mapboxMap = allProvince1.getMapboxMap();
            mapboxMap.on("load", () => {
                this.$nextTick(() => {
                    let myContainer = mapboxMap.getContainer();
                    myContainer.className = `leaflet-gl-layer mapboxgl-map dxtMap`;
                    // myContainer.style.zIndex = 4;
                });
            });
        },
        //底图切换
        baseLayerChange(maptype) {
            this.mapType = maptype;
            switch (maptype) {
                case "vector":
                    this.mapData.baseMapNum = 0;
                    break;
                case "img":
                    this.mapData.baseMapNum = 1;
                    break;
            }
            this.loadBaseLayer(this.mapData.baseMapNum);
        },
        // 基础底图
        loadVectorMap() {
            if (Array.isArray(this.mapInfo.url)) {
                this.mapInfo.url.forEach((item) => {
                    this.getBasicLayerStyle(item);
                });
            } else {
                this.getBasicLayerStyle(this.mapInfo.url);
            }
        },
        async getBasicLayerStyle(url) {
            $AXIOS({
                method: "get",
                url: url,
            }).then((res) => {
                window.L.mapboxGL({
                    accessToken: window.MAPBOX_TOKEN,
                    style: res.data,
                }).addTo(this.basicMap);
                this.dealVectorLayers();
            });
        },
        // 修改矢量切片服务加载后的样式，达到叠加多个地图的功能
        dealVectorLayers() {
            let vectorLayers = document.querySelectorAll(".mapboxgl-map");
            vectorLayers.forEach((layer) => {
                layer.style.position = "absolute";
                layer.style.left = 0;
                layer.style.top = 0;
            });
        },
        // 地图复位
        changeFitBounds(lat, lng, level = 18) {
            this.$emit("reSetMap");
            if (lat && lng) {
                this.basicMap.flyTo([lat, lng], level);
            } else {
                this.basicMap.flyToBounds(this.mapInfo.fitBounds);
            }
        },
        //获取片区数据
        async getAreaInfo() {
            let result = await this.GetRcxcXcpq({});
            result.map((item, index) => {
                let areaItem = JSON.parse(item.QYZB);
                this.derectionData.map((itemInner) => {
                    if (item.ID == itemInner.ID) {
                        if (itemInner.JD) {
                            Object.assign(item, itemInner);
                        } else {
                            let center = window.turf.centerOfMass(areaItem);
                            itemInner.JD = center.geometry.coordinates[0];
                            itemInner.WD = center.geometry.coordinates[1];
                            Object.assign(item, itemInner);
                        }
                    }
                });
            });
            this.areaList = result;
            this.drawXcq(this.areaGroup, this.areaList);
        },
        // 画 巡查区
        drawXcq(group, list) {
            let areaColorList = [
                "#5a91fb",
                "#5ad9a7",
                "#5c7091",
                "#f7bd16",
                "#e86452",
                "#6ec9eb",
                "#955eb9",
                "#ff9944",
                "#1e9593",
                "#ff99c3",
                "#81511c",
                "#7d0022",
                "#556fb5",
                "#009944",
                "#0068b7",
                "#d31247",
                "#f260ce",
                "#7148f5",
                "#94b750",
                "#0b3da9",
                "#f4da80",
                "#863803",
                "#d2c7b6",
            ];
            list.map((item, index) => {
                if (!item.QYZB) {
                    return;
                }
                let areaItem = JSON.parse(item.QYZB);
                // 巡查区
                let layer = window.L.geoJSON(areaItem, {
                    style: () => {
                        return { color: item.YS };
                    },
                }).addTo(group);
                // layer.on("mouseover", () => {
                //     let lng = 0;
                //     let lat = 0;
                //     if (item.JD) {
                //         lng = item.JD;
                //         lat = item.WD;
                //     } else {
                //         let center = window.turf.centerOfMass(areaItem);
                //         lat = center.geometry.coordinates[1];
                //         lng = center.geometry.coordinates[0];
                //     }
                //     this.markerPopup
                //         .setLatLng([lat, lng])
                //         .setContent(`<div class="tip">${item.QYMC}</div>`)
                //         .openOn(this.basicMap);
                // });
                // layer.on("mouseout", () => {
                //     this.markerPopup.removeFrom(this.basicMap);
                // });
            });
        },
        drawXcqLabel(group, list) {
            this.clearLayersGroup(group);
            list.map((item, index) => {
                let width = 12 * item.QYMC.length;
                let icon = window.L.divIcon({
                    html: `<div style="width:${width}px;height:16px;white-space:nowrap;color:#000;font-size:12px;font-weight:600;">${item.QYMC}</div>`,
                    className: "",
                    iconSize: [width, 16],
                    iconAnchor: [width / 2, 8],
                });

                let layer = window.L.marker([item.WD, item.JD], {
                    icon: icon,
                }).addTo(group);
            });
        },
        // 加载WMTS图层
        getWmtsLayer(layerName) {
            let url = `https://t{s}.tianditu.gov.cn/${layerName}_w/wmts`;
            let layer = new window.L.TileLayer.WMTS(url, {
                tk: window.tk,
                zIndex: 0,
                tileSize: 256,
                crs: window.L.CRS.EPSG3857,
                layer: layerName,
                style: "default",
                tilematrixSet: "w",
                format: "tiles",
                subdomains: "01234567",
                minZoom: 3,
                maxZoom: 17,
                isBaseLayer: true,
            });
            return layer;
        },
    },
};
</script>

<style scoped lang="scss">
.map {
    width: 100%;
    height: 100%;
    position: relative;
    .resetIcon {
        position: absolute;
        right: 50px;
        top: 10px;
        z-index: 100;
        width: 40px;
        height: 40px;
    }
    .compassPart {
        position: absolute;
        right: 0px;
        top: 10px;
        z-index: 10;
        width: 50px;
    }
    .toolBox {
        position: absolute;
        z-index: 999;
        right: 100px;
        top: 10px;
    }
}

.myMap {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
}
#mapType {
    position: absolute;
    right: 10px;
    bottom: 30px;
    height: 70px;
    width: 106px;
    z-index: 999;
    border-radius: 2px;
    cursor: pointer;
    transition: width 0.8s;
    background: rgba(255, 255, 255, 0.3);
    &:hover {
        width: 288px;
        width: 200px;
        .vectorType {
            right: 197px;
            right: 101px;
        }

        .imgType {
            right: 101px;
            right: 5px;
        }

        .terType {
            right: 5px;
        }
    }
    .mapTypeCard {
        position: absolute;
        top: 5px;
        width: 86px;
        height: 60px;
        border-radius: 5px;
        border: solid 1px rgba(255, 255, 255, 0);
        span {
            position: absolute;
            bottom: -1px;
            left: -1px;
            width: 86px;
            display: inline-block;
            font-size: 12px;
            height: 16px;
            line-height: 16px;
            text-align: center;
            color: #fff;
            background: #888f88;
            opacity: 0.8;
        }
    }

    .vectorType {
        right: 15px;
        background: url("~@image/mapToolbar/mapbtn.png") no-repeat 0 0;
        z-index: 3;
        transition: right 0.8s;
        &:hover {
            border: solid 1px #31a5f7;
            span {
                background: #31a5f7;
            }
        }
    }

    .imgType {
        right: 10px;
        background: url("~@image/mapToolbar/imagebtn.png") no-repeat 0 0;
        z-index: 2;
        transition: right 0.8s;
        &:hover {
            border: solid 1px #31a5f7;
            span {
                background: #31a5f7;
            }
        }
    }

    .terType {
        right: 5px;
        background: url("~@image/mapToolbar/terbtn.png") no-repeat 0 0;
        z-index: 1;
        transition: right 0.8s;
        &:hover {
            border: solid 1px #31a5f7;
            span {
                background: #31a5f7;
            }
        }
    }
    .active {
        border: solid 1px #31a5f7;
        span {
            background: #31a5f7;
        }
    }
}
</style>
