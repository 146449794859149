<template>
    <div class="myTool">
        <div class="toggle" @click="toggleTool">
            <img
                style="text-align: center"
                src="@image/map/mapImg/mapTool/mapTool.png"
                title="工具面板"
                alt
            />
        </div>
        <!-- <transition name="tool-trans"> -->
        <div class="barBox" :style="{ width: showTool ? '228px' : '0' }">
            <div class="mapToolBar">
                <!-- <div
                        class="item control-layers"
                        :class="{ active: currentTool == 'layers' }"
                        @click="control_layers"
                    >
                        <i></i>
                        <span>图层</span>
                    </div> -->
                <div class="item measure-distance" @click="control_distance">
                    <i></i>
                    <span>测距</span>
                </div>
                <div
                    class="item measure-area"
                    :class="{ active: currentTool == 'area' }"
                    @click="control_area($event)"
                >
                    <i></i>
                    <span>量面</span>
                </div>
                <div
                    class="item location"
                    :class="{ active: currentTool == 'location' }"
                    @click="control_location()"
                >
                    <i></i>
                    <span>定位</span>
                </div>
            </div>
        </div>
        <!-- </transition> -->
        <control-layer
            v-show="currentTool == 'layers'"
            ref="controllayer"
            class="items control-layers"
            :basicMap.sync="basicMap"
            :currentTool.sync="currentTool"
            :opacity="opacity"
            @changeVectortile="changeVectortile"
        ></control-layer>
        <measure-distance
            ref="child"
            v-show="currentTool == 'distance'"
            class="items measure-distance"
            :basicMap.sync="basicMap"
        ></measure-distance>
        <measure-area
            v-if="currentTool == 'area'"
            class="items measure-area"
            :basicMap.sync="basicMap"
            :currentTool.sync="currentTool"
        ></measure-area>
        <location
            v-show="currentTool == 'location'"
            :basicMap.sync="basicMap"
            :currentTool.sync="currentTool"
        ></location>
    </div>
</template>
<script>
const ControlLayer = () => import("./tool/control-layers.vue");
const MeasureArea = () => import("./tool/measure-area.vue");
const MeasureDistance = () => import("./tool/measure-distance.vue");
const Location = () => import("./tool/location.vue");
export default {
    name: "mapTool",
    components: {
        ControlLayer,
        MeasureArea,
        MeasureDistance,
        Location,
    },
    props: {
        basicMap: {
            type: Object,
            default: () => {},
        },
        opacity: {
            type: Number,
            default: () => {
                return 1;
            },
        },
    },
    data() {
        return {
            showTool: false,
            currentTool: "",
        };
    },
    computed: {},
    mounted() {},
    methods: {
        // 控制工具栏显隐
        toggleTool() {
            this.showTool = !this.showTool;
            this.currentTool = "";
        },
        // 图层控制
        control_layers() {
            this.currentTool = this.currentTool == "layers" ? "" : "layers";
        },
        // 测距
        control_distance() {
            this.currentTool = "distance";
        },
        // 量面
        control_area() {
            this.currentTool = this.currentTool == "area" ? "" : "area";
        },
        // 定位
        control_location() {
            this.currentTool = this.currentTool == "location" ? "" : "location";
        },
        changeVectortile(v) {
            this.$emit("changeVectortile", v);
        },
    },
};
</script>

<style scoped lang="scss">
.toggle {
    height: 40px;
    width: 40px;
    background-clip: padding-box;
    cursor: pointer;
    border-radius: 5px;
    background: #1a3059;
    border: 1px solid #35507c;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2), 0 -1px 0px rgba(0, 0, 0, 0.02);
    text-align: center;
    color: #fff;
    img {
        width: 18px;
        margin-top: 10px;
    }
}
.barBox {
    width: 0;
    height: 40px;
    position: absolute;
    top: 0;
    right: 55px;
    z-index: 999;
    transition: all 0.6s;
    overflow: hidden;
}
.tool-trans-enter-active {
    transition: all 0.8s;
}
.tool-trans-leave-active {
    transition: all 0.8s;
}
.tool-trans-enter,
.tool-trans-leave-to {
    transform: translateX(255px);
}
.mapToolBar {
    height: 40px;
    background: #1a3059;
    border: 1px solid #35507c;
    color: #fff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2), 0 -1px 0px rgba(0, 0, 0, 0.02);
    .item {
        float: left;
        height: 100%;
        width: 75px;
        line-height: 30px;
        cursor: pointer;
        text-align: center;
        overflow: hidden;
        font-size: 12px;
        position: relative;
        margin: 5px 0;
        &:hover {
            color: #4285f4;
        }
        .active {
            color: #4285f4;
        }
        &:before {
            content: "";
            position: relative;
            right: 16px;
            top: 0px;
            border-left: 1px solid #ddd;
            height: 28px;
        }
        i {
            display: inline-block;
            width: 18px;
            height: 18px;
            position: relative;
            top: 5px;
            right: 4px;
            background: url("~@image/map/mapImg/mapTool/map_tool_bar.png")
                no-repeat;
        }
        &:first-child:before {
            content: "";
            border: none;
        }
    }

    .control-layers i {
        background-position: 0px -257px;
    }

    .control-layers:hover i,
    .control-layers.active i {
        background-position: 0px -321px;
    }

    .measure-distance i {
        background-position: 0px 0px;
    }

    .measure-distance:hover i,
    .measure-distance.active i {
        background-position: -1px -64px;
    }

    .measure-area i {
        background-position: 0px -386px;
    }

    .measure-area:hover i,
    .measure-area.active i {
        background-position: 0px -457px;
    }

    .location i {
        background-position: 0px -130px;
    }

    .location:hover i,
    .location.active i {
        background-position: 0px -193px;
    }
}
</style>